export const PaginationMixin = {
  data: () => ({
    pagination: {
      objects: 0,
      page: 1,
      pages: 1,
      limit: 10,
    },
  }),
  methods: {
    setPaginationFromResponse(pagination = null) {
      if (pagination) {
        this.pagination.limit = parseInt(pagination.limite ?? 10) ?? 10;
        this.pagination.page = parseInt(pagination.pagina ?? 1) ?? 1;
        this.pagination.pages = parseInt(pagination.numero_paginas ?? 1) ?? 1;
        this.pagination.objects = parseInt(pagination.registros ?? 0) ?? 0;
      }
    },
    setPaginationFromTable(pagination = null) {
      if (pagination) {
        this.pagination.limit = pagination.itemsPerPage ?? 10;
        this.pagination.page = pagination.page ?? 1;
        this.setup();
      }
    },
    setOrResetPagination(pagination = null) {
      if (pagination) {
        this.setPaginationFromResponse(pagination);
      } else {
        this.resetPagination();
      }
    },
    resetPagination() {
      this.pagination.page = 1;
      this.pagination.pages = 1;
      this.pagination.limit = 10;
      this.pagination.objects = 0;
    },
    filter() {
      this.pagination.page = 1;
      this.setup();
    },
  },
};

export default {
  PaginationMixin,
};
